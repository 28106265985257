import React from "react";
import { GlobalStyle } from "../styles/GlobalStyle";
import "./layout.css";
import Header from "./Header";
// import firebase from "gatsby-plugin-firebase";
// import "firebase/auth";
// import "firebase/firestore";
// import "firebase/functions";
import styled from "styled-components";


function Layout({ children }) {

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

  return (
    <>
      <Wrapper>
        <GlobalStyle></GlobalStyle>
        <Stars src="/images/backgrounds/stars.svg" />
        <Header />
        <main>{children}</main>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
`;

const Stars = styled.img`
  position: absolute;
  width: 100%;
  background-position: center top;
  background-repeat: repeat;
  z-index: 0;
`;

export default Layout;
