export const menuData = [
  { title: "Acasă", icon: "/images/icons/courses.svg", link: "#home" },
  {
    title: "Servicii",
    icon: "/images/icons/tutorials.svg",
    link: "#services",
  },
  { title: "Contact", icon: "/images/icons/comment.svg", link: "#contact" },
  // { title: "", icon: "/images/icons/search.svg", link: "/search" },
  // { title: "", icon: "/images/icons/account.svg", link: "/account" },
];

export const tooltipData = [
  { title: "Profile", icon: "/images/icons/profile.svg", link: "/profile" },
  { title: "Settings", icon: "/images/icons/settings.svg", link: "/settings" },
  { title: "Sign Out", icon: "/images/icons/signout.svg", link: "/logout" },
];

export const burgerData = [
  { title: "Acasă", icon: "/images/icons/courses.svg", link: "#home" },
  {
    title: "Servicii",
    icon: "/images/icons/tutorials.svg",
    link: "#services",
  },
  { title: "Contact", icon: "/images/icons/comment.svg", link: "#contact" },
  // { title: "Profile", icon: "/images/icons/profile.svg", link: "/profile" },
  // { title: "Settings", icon: "/images/icons/settings.svg", link: "/settings" },
  // { title: "Sign Out", icon: "/images/icons/signout.svg", link: "/logout" },
];
